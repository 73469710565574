import React from "react";
// import "@/styles/globals.css";
import axios from "axios";
import "../styles/globals.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
// import "@/styles/scroll-area.css";
import type { AppProps } from "next/app";
import { QueryClient, QueryClientProvider } from "react-query";

export const DMEtheme = createTheme({
  palette: {
    primary: {
      main: "#bf0000",
      light: "#F0EAEA",
      dark: "#1D0101",
      contrastText: "#F0EAEA",
    },
    secondary: { main: "#154d61" },
  },
  typography: {
    allVariants: {
      fontFamily: "Montserrat",
      textTransform: "none",
    },
    h1: {
      fontFamily: "Montserrat-Semibold",
      textTransform: "none",
      fontSize: 60,
      fontWeight: "bold",
    },
    h2: {
      fontFamily: "Montserrat-Semibold",
      textTransform: "none",
      fontSize: 40,
      fontWeight: "bold",
    },
    h3: {
      fontFamily: "Montserrat-Semibold",
      textTransform: "none",
      fontSize: 20,
      fontWeight: "bold",
    },
    h4: {
      fontFamily: "Montserrat-Semibold",
      textTransform: "none",
      fontSize: 20,
      fontWeight: "bold",
    },
    h5: {
      fontFamily: "Montserrat-Semibold",
      textTransform: "none",
      fontSize: 20,
      fontWeight: "bold",
    },
  },
});
const queryClient = new QueryClient();
axios.defaults.baseURL = "https://dme-staging-1f15eaad7fd6.herokuapp.com/api/"; // replace with .env variable
export default function App({ Component, pageProps }: AppProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={DMEtheme}>
        <Component {...pageProps} />
      </ThemeProvider>
    </QueryClientProvider>
  );
}
